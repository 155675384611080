<template>
    <div class="distributionOrder">
        <div class="search">
            <a-input class="search-item" v-model="OrderNo" placeholder="订单号"></a-input>
            <a-input class="search-item" v-model="Name" placeholder="商品名称"></a-input>
            <a-input class="search-item" placeholder="分销员手机号"></a-input>
            <a-input class="search-item" v-model="BuyPhoneNumber" placeholder="会员手机号"></a-input>
            <!--<a-input class="search-item" placeholder="订单平台"></a-input>-->
            <a-range-picker class="search-item" style="width: 240px" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" @change="changeTime"/>
            <a-button style="width: 80px" class="search-item" @click="_reseat()">重 置</a-button>
            <a-button style="width: 80px;color: #ffffff" class="search-item" type="primary" @click="search()">查 询</a-button>
        </div>
        <div style="margin-bottom: 20px">
            <a-button :style="!Status&&Status!==0 ?'color:#ffffff':''" :type="!Status&&Status!==0?'primary':''" class="status" @click="_status('')">全部({{total}})</a-button>
            <a-button :style="Status===0?'color:#ffffff':''" :type="Status===0?'primary':''" class="status" @click="_status(0)">待付款</a-button>
            <a-button :style="Status==1?'color:#ffffff':''" :type="Status==1?'primary':''" class="status" @click="_status(1)">已成交</a-button>
            <a-button :style="Status==2?'color:#ffffff':''" :type="Status==2?'primary':''" class="status" @click="_status(2)">已完成</a-button>
            <a-button :style="Status==3?'color:#ffffff':''" :type="Status==3?'primary':''" class="status" @click="_status(3)">已退款</a-button>
        </div>
        <div class="list" :style="listData.length>0?'border-bottom:0':''">
            <div class="list-info-title" style="border-bottom:0">
                <div style="width: 20%" class="list-item list-title">商品</div>
                <div class="list-item list-title">单价</div>
                <div class="list-item list-title">数量</div>
                <div class="list-item list-title">买家</div>
                <div class="list-item list-title">实收</div>
                <div class="list-item list-title">订单状态</div>
                <div style="flex:1" class="list-item list-title">操作</div>
            </div>
        </div>
        <div class="list" v-if="listData.length>0" v-for="item in listData" style="margin-bottom: 10px">
            <div class="list-order-num">
                <div class="list-order-info"> 订单号： {{item.OrderNo}}</div>
                <div class="list-order-info"> 下单时间： {{item.CreateTime}}</div>
                <div class="flex list-order-info">
                    <div>订单类型：</div>
                    <div class="list-order-type">{{item.Type}}</div>
                </div>
            </div>
            <div class="list-info-title" style="padding: 10px 0px;">
                <div style="width: 20%;display: flex" class="list-item">
                    <a-avatar shape="square" :size="62" :src="item.Icon"/>
                    <div style="margin-left: 10px">
                        <div>订单号： {{item.OrderNo}}</div>
                        <div class="check-payment">审核打款</div>
                        <div class="check-payment-active">审核打款</div>
                    </div>
                </div>
                <div class="list-item ">￥{{item.AvgPrice}}</div>
                <div class="list-item ">{{item.Num}}</div>
                <div class="list-item ">
                    <div style="display: flex">
                        <a-avatar :size="22" :src="item.BuyUserAvatarUrl"/>
                        <div style="margin-left: 5px;margin-bottom: 8px" class="check-payment">{{item.BuyUserName}}
                        </div>
                    </div>
                    <div>{{item.BuyUserPhoneNumber}}</div>
                </div>
                <div class="list-item ">
                    <div>商品总额:{{item.TotalPrice}}</div>
                    <div>优惠:{{item.DiscountedPrice}}</div>
                    <div>运费:{{item.FreightFee}}</div>
                    <div style="margin-top: 10px">实收金额:{{item.Amount}}</div>
                </div>
                <div class="list-item ">
                    <div class="order-status">完成</div>
                    <div class="check-payment" @click="_info(item.Id)">订单详情</div>
                </div>
                <div style="flex:1;display: flex" class="list-item ">
                    <div style="margin-right: 5px" class="check-payment">发货</div>
                    <div style="margin-right: 5px" class="check-payment">修改地址</div>
                    <div style="margin-right: 5px" class="check-payment">备注</div>
                    <div class="check-payment">审核打款</div>
                </div>
            </div>
            <div class="commission">
                <div class="commission-info">一级佣金：{{item.ReturningServantMoney}}元</div>
                <div class="commission-info">二级佣金：{{item.SecondReturningServantMoney}}元</div>
            </div>
        </div>
        <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" v-model="Page" @change="ChangePage"
                      :pageSize="Limit" :total="total" show-less-items/>
    </div>
</template>

<script>
    export default {
        name: "distributionOrder",
        data() {
            return {
                listData: [],
                total: 0,
                Page: 0,
                Limit: 10,
                OrderNo: '',
                Name:'',
                BuyPhoneNumber:'',
                StartTime:'',
                EndTime:'',
                Status:'',
            }
        },
        props: {
            UserId: {
                type: String,

            },
        },
        created() {
            this.Orderlist();
        },
        methods: {
            Orderlist() {
                let self = this;
                this.$message.loading('加载中.....',0);
                this.$axios.get(1240, {
                    DistributorId: self.UserId,
                    pagesize: self.Limit,
                    p: self.Page,
                    OrderNo: self.OrderNo,
                    Name:self.Name,
                    BuyPhoneNumber:self.BuyPhoneNumber,
                    EndTime:self.EndTime,
                    StartTime:self.StartTime,
                    Status:self.Status,
                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.listData = res.data.data;
                        self.total = res.data.total;
                    } else {
                        self.listData = [];
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _status(i){
                 this.Status = i;
                 this.Orderlist();
            },
            ChangePage(val){
                this.Page = val;
                this.Orderlist();
            },
            search(){
                this.Page = 0;
                this.Orderlist();
            },
            _reseat(){
                this.OrderNo = '';
                this.Name ='';
                this.BuyPhoneNumber = '';
                this.Page = 0;
                this.StartTime = '';
                this.EndTime = '';
                this.Status = 0;
                this.Orderlist();
            },
            changeTime(value, dateString) {
                this.StartTime =  dateString[0];
                this.EndTime =  dateString[1];
            },
            _info(id){
                this.$router.push({path: '/disOrderInfo',query:{id:id}})
            },
        }
    }
</script>

<style scoped lang="less">
    .distributionOrder{
        background: #ffffff;
        padding: 20px;
    }
    * {
        color: #1D1D1D;
        font-size: 12px;
    }

    .search {
        display: flex;
        margin-bottom: 10px;
        .search-item {
            width: 156px;
            margin-right: 10px;
            color: #666666;
        }
    }

    .list {
        border: 1px solid #E0E0E0;
        border-radius: 3px;
        .list-info-title {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;
            .list-item {
                width: 12%;
                padding-left: 10px;
            }
            .list-title {
                height: 36px;
                line-height: 36px;
                background: #F9F9F9;

            }
        }
        .list-order-num {
            display: flex;
            height: 36px;
            background: #EAF8FF;
            border-bottom: 1px solid #E0E0E0;
            align-items: center;
            .list-order-info {
                margin-left: 10px;
                color: #1D1D1D;
                font-size: 12px;
                .list-order-type {
                    color: #ffffff;
                    padding: 3px 8px;
                    background: #FB6638;
                    font-size: 12px;
                    border-radius: 5px;
                }
            }
        }

    }

    .check-payment {
        color: #2994FF;
    }

    .check-payment-active {
        color: #FB7850;
    }

    .order-status {
        color: #FFFFFF;
        width: 45px;
        height: 21px;
        background: #2994FF;
        text-align: center;
        line-height: 21px;
        font-size: 12px;
        border-radius: 2px;
        margin-bottom: 8px;
    }

    .commission {
        display: flex;
        align-items: center;
        padding: 10px;
        .commission-info {
            color: #ffffff;
            font-size: 12px;
            background: #FB6638;
            border-radius: 2px;
            padding: 2px 10px;
            margin-left: 15px;
        }
    }
    .status{
        width: 80px;
        margin-right: 8px;
    }
</style>